import Swal from 'sweetalert2';

export function showLoadingIndicator() {
    Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false,
        icon: "info",
        onBeforeOpen: () => Swal.showLoading(),
    });
}

export function hideLoadingIndicator() {
    Swal.close();
}
