export const appUrls = {
    backend_api: process.env.REACT_APP_BACKEND_API,
    backend_jobs: process.env.REACT_APP_BACKEND_JOBS,
    backend_api_image: "https://img.knaek.com/",
}

export const appLocalStoargeValues = {
    language: 'language',
    user_data: 'user_data',
}


export const appToastMessages = {
    words_data_required: 'You didnt put any words in the fields',
    all_fields_are_required: 'All fields are required',
    notification_has_not_been_sent: 'Notification has not been sent',
}
export const NotificationToastMessages = {
    instore_required: 'Instore Discount is required',
    cashback_required: 'Online Discount is required',
    job_required: 'Jobs Discount is required',
    url_required: 'URL is required'
}

export const toastOptions = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    limit:5
};

export const DiscountType = {
    COUPONS: 'COUPONS',
    VOUCHERS: 'VOUCHERS',
    EXTERNAL_COUPON: 'EXTERNAL_COUPON'
}