import React from 'react';

const NotificationItem = ({ imagePath, iconId, title, subtitle }) => {
    return (
        <div className="widget-media mb-2">
            <ul className="timeline">
                <li className="cursor-pointer">
                    <div className="timeline-panel">
                        <div className="media me-2">
                            <img alt="" width="50" src={imagePath + iconId} />
                        </div>
                        <div className="media-body">
                            <h5 className="mb-1">{title}</h5>
                            <small className="d-block">{subtitle}</small>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    );
};

export default NotificationItem;
