import React,{useEffect, useState, useMemo}from 'react'
import {MoreInfo} from "../MoreInfo";
import {ButtonLoading} from '../Common/ButtonLoading'
import Select from "react-select";
import swal from "sweetalert";
import {ClearIndicator, ClearIndicatorStyles, ControlStyles, MultiValueStyles, handleError, haveIdenticalKeys} from "../../utils/SharedFunctions";
import {createCompanyCall, deleteCompanyByIdCall, getSubscriptionTypes, fetchFilterProductionNotificationCall,getCategories,updateCompanyCall} from "../../Api/ApiCalls";
import { toast } from "react-toastify";
import { toastOptions } from '../../config/Constants';
import {useHistory} from 'react-router-dom'
import { TooltipMessagesTypes } from '../../enums/tooltipMessagesTypes';

const defaultFormValues={
  name: "",
  logo: "",
  backgroundImage: "",
  categoryId: "",
  website: "",
  isFreeToUse: false,
  isDisabled: false,
  isSendStatistics: false,
  appWeight: 0,
  webWeight: 0,
  email: "",
  verificationCode: "",
  accountOwner: "",
  cityIds: [],
  companySubscriptionType: "",
}


export const CreateUpdateCompanyForm = (props) => {
  const [formData, setFormData] = useState(props?.formData ?? defaultFormValues);
  const [formImagePreview, setFormImagePreview] = useState({backgroundImage:props.formData?.backgroundImage ?? "", logo:props.formData?.logo ?? ""});
  // filters
  const [accountOwners, setAccountOwners] = useState([])
  const [cities, setCities] = useState([])
  const [categories, setCategories] = useState([]);
  const [subscriptionTypes, setSubscriptionTypes] = useState([]);


  const [isSubmiting, setIsSubmiting] = useState(false);

  const selectedAccountOwner = useMemo(() => accountOwners.find(accountOwner=>accountOwner.value===formData.accountOwner), [accountOwners, formData.accountOwner])
  const selectedCategory = useMemo(() => categories.find(category=>category.id===formData.categoryId), [categories, formData.categoryId])
  const selectedCities = useMemo(() => cities.filter(city=>formData.cityIds?.includes(city.value)), [cities, formData.cityIds])
  const selectedSubscriptionType = useMemo(() => subscriptionTypes.find(subscription=> subscription.value === formData.companySubscriptionType), [subscriptionTypes, formData.companySubscriptionType])

  const history = useHistory()


  useEffect(() => {
    fetchCategories();
    fetchFilters();
    fetchSubscriptionTypes();
  }, []);

  return (
    <form onSubmit={handleCompanySubmit}>
    <div className="modal-header">
      <h4 className="modal-title fs-20">{props.isUpdate? "Update" : "New"} Company</h4>
      <button type="button" className="btn-close" onClick={() => props.setShowModal(false)} data-dismiss="modal"></button>
    </div>
    <div className="modal-body">
      <i className="flaticon-cancel-12 close"></i>
      <div className="add-contact-box">
        <div className="add-contact-content">
          <div className={"row"}>
            <div className="form-group mb-3 col-sm-10 col-md-6">
              <label className="text-black font-w500">Name*</label>
              <div className="contact-name">
                <input
                    type="text"
                    className="form-control"
                    name="name"
                    required
                    value={formData.name}
                    onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="form-group mb-3 col-sm-10 col-md-6">
              <label className="text-black font-w500">Category*</label>
              <Select
                    name='categoryId'
                    styles={{
                      clearIndicator: ClearIndicatorStyles,
                      control: ControlStyles,
                    }}
                    value={selectedCategory}
                    options={categories}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    onChange={(category) => {
                      setFormData({ ...formData, 'categoryId': category.id });
                    }}
                    placeholder="Select Category"
                    required
                />
            </div>


            <div className="form-group mb-3 col-sm-10 col-md-6">
              <label className="text-black font-w500">App weight*</label>
              <div className="contact-name">
                <input
                    type="number"
                    min={0}
                    max={100}
                    className="form-control"
                    name="appWeight"
                    required
                    value={formData.appWeight}
                    onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="form-group mb-3 col-sm-10 col-md-6">
              <label className="text-black font-w500">Web weight*</label>
              <div className="contact-name">
                <input
                    type="number"
                    min={0}
                    max={100}
                    className="form-control"
                    name="webWeight"
                    required
                    value={formData.webWeight}
                    onChange={handleInputChange}
                />
              </div>
            </div>


            <div className="form-group mb-3 col-sm-10 col-md-6">
              <label className="text-black font-w500">
                Website URL*
              </label>
              <div className="contact-name">
                <input
                    type="text"
                    placeholder="http://www.example.com"
                    className="form-control"
                    name="website"
                    required
                    value={formData.website}
                    onChange={handleInputChange}
                    onBlur={validateUrl}
                />
              </div>
            </div>

            {/*Cities*/}
            <div className="form-group mb-3 col-sm-10 col-md-6">
              <label className="text-black font-w500">
                Cities*
              </label>
              <Select
                  className="basic-multi-select"
                  closeMenuOnSelect={false}
                  components={{ClearIndicator}}
                  styles={{
                    clearIndicator: ClearIndicatorStyles,
                    control: ControlStyles,
                    multiValue: MultiValueStyles
                  }}
                  value={selectedCities}
                  options={cities}
                  getOptionLabel={option => option.label}
                  getOptionValue={option => option.value}
                  onChange={(cities) => {
                    setFormData({ ...formData, 'cityIds': cities.map(city=>city.value) });
                  }}
                  isMulti
                  required
              />
            </div>

            <div className="form-group mb-3 col-sm-10 col-md-6">
              <label className="text-black font-w500">
                Email*
              </label>
              <div className="contact-name">
                <input
                    type="email"
                    className="form-control"
                    name="email"
                    required
                    value={formData.email}
                    onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="form-group mb-3 col-sm-10 col-md-6">
              <label className="text-black font-w500">
                Verification Code*
              </label>
              <div className="contact-name">
                <input
                    className="form-control"
                    name="verificationCode"
                    value={formData.verificationCode}
                    onChange={handleInputChange}
                    required
                />
              </div>
            </div>

            {/*company_subscription_type*/}
            <div className="form-group mb-3 col-sm-10 col-md-6">
              <label className="text-black font-w500">
                  Subscription Type*
              </label>
              <div className="contact-name">
                <Select
                    name='companySubscriptionType'
                    styles={{
                      clearIndicator: ClearIndicatorStyles,
                      control: ControlStyles,
                    }}
                    value={selectedSubscriptionType}
                    options={subscriptionTypes}
                    onChange={(subscription) => {
                      setFormData({ ...formData, companySubscriptionType: subscription.value });
                    }}
                    placeholder="Select subscription type"
                    required
                  />
              </div>
            </div>

              {/*Accounts*/}
              <div className="form-group mb-3 col-sm-10 col-md-6">
              <label className="text-black font-w500">
                Account Owner*
              </label>
                <Select
                    name="accountOwner"
                    className="basic-multi-select"
                    styles={{
                      clearIndicator: ClearIndicatorStyles,
                      control: ControlStyles,
                    }}
                    value={selectedAccountOwner}
                    required
                    options={accountOwners}
                    onChange={(accountOwner) => {
                      setFormData({ ...formData, 'accountOwner': accountOwner.value });
                    }}
                />
            </div>

            <div className="form-group mb-3 col-sm-10 col-md-12">
              <div className="d-flex justify-content-between">
                <div className="form-group mb-3">
                  <label className="text-black font-w500">
                    Can be used in limited mode? {props.isUpdate && <span className="text-primary">(Free to use)</span>}
                  </label>
                  <input
                    type="checkbox"
                    name="isFreeToUse"
                    checked={formData.isFreeToUse}
                    onChange={handleInputChange}
                    className="form-check-input ms-2"
                  />
                </div>

                <div className="form-group mb-3">
                  <label className="text-black font-w500">
                    Send Statistic Update for Company?
                  </label>
                  <input
                    type="checkbox"
                    name="isSendStatistics"
                    checked={formData.isSendStatistics}
                    onChange={handleInputChange}
                    className="form-check-input ms-2"
                  />
                </div>

                <div className="form-group mb-3">
                  <label className="text-black font-w500">
                    Hide Company?
                  </label>
                  <input
                    type="checkbox"
                    name="isDisabled"
                    checked={formData.isDisabled}
                    onChange={handleInputChange}
                    className="form-check-input ms-2"
                  />
                </div>
              </div>
            </div>

            <h3 className={"text-primary mt-3"}>Files</h3>
            <div className="form-group mb-3 col-sm-10 col-md-6">
                <label className="text-black font-w500 d-flex align-items-center">
                    Background Image*
                    <MoreInfo content={[TooltipMessagesTypes.DIMENSIONS_1024_768, TooltipMessagesTypes.FORMAT_JPG, TooltipMessagesTypes.SIZE_250]} />
                </label>
                <div className="contact-name">
                    <input
                        type="file"
                        className="form-control"
                        name="backgroundImage"
                        required={!props.isUpdate}
                        onChange={(e) => handleInputChangeFile(e)}
                    />
                </div>
            </div>
            <div className="form-group mb-3 col-sm-10 col-md-6">
              <label className="text-black font-w500 d-flex align-items-center">
                Logo
                <MoreInfo content={[TooltipMessagesTypes.DIMENSIONS_250_250, TooltipMessagesTypes.FORMAT_JPG, TooltipMessagesTypes.SIZE_50]} />
                </label>
              <div className="contact-name">
                <input
                    type="file"
                    className="form-control"
                    name="logo"
                    onChange={(e) => handleInputChangeFile(e)}
                />
              </div>
            </div>
            <div className="form-group mb-3 col-sm-10 col-md-6">
                <div>
                    <div>
                        <div
                            className="image-container p-1"
                            style={{
                                width: "1024px",
                                height: "396px",
                                overflow: "hidden",
                                border: "1px solid #ccc",
                            }}
                        >
                          {
                            formImagePreview?.backgroundImage && (
                              <img
                                  id="uploadedImage"
                                  src={formImagePreview?.backgroundImage} // Use the preview URL here
                                  alt="Uploaded Image"
                                  style={{width: "100%", height: "100%"}}
                              />
                            )
                          }
                        </div>
                    </div>
                </div>
            </div>

            <div className="form-group mb-3 col-sm-10 col-md-6">
                <div>
                    <div>

                        <div
                            className="image-container p-1"
                            style={{
                                width: "250px",
                                height: "250px",
                                overflow: "hidden",
                                border: "1px solid #ccc",
                            }}
                        >
                        {
                          formImagePreview?.logo && (
                            <img
                                id="uploadedImage"
                                src={formImagePreview?.logo} // Use the preview URL here
                                alt="Uploaded Image"
                                style={{width: "100%", height: "100%"}}
                            />
                          )
                        }
                        </div>
                    </div>
                </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    {/* Footer */}
    <div className="modal-footer">
        <button className="btn btn-secondary" type="submit" disabled={isSubmiting}>
            <ButtonLoading isLoading={isSubmiting} text={props.isUpdate?"Save":"Add"} />
        </button>
        <button type="button" className="btn btn-warning" onClick={() => props.setShowModal(false)}>
          {props.isUpdate?"Cancel":"Discard"}
        </button>
        {props.isUpdate && <button className="btn btn-danger" type="button" onClick={() => deleteCompany()}>
            Delete
        </button>}
    </div>
  </form>
  );


  function deleteCompany() {
    swal({
      title: "Are you sure?",
      text: "you want to permanently delete this company?\nThis action can not be undone and it may take some time.",
      icon: "warning",
      buttons: {
        cancel: true,
        ok :{
          text: "Ok",
          value: "ok",
          className: "swal-button--danger",
          closeModal: false,
        },
    },
    }).then((response) => {
      if (response === 'ok') {
        deleteCompanyByIdCall(props?.companyId)
        .then((result) => {
          swal.stopLoading();
          swal.close();
          props?.setShowModal(false);
          toast.success("Company has been deleted successfully", toastOptions);
          history.push('/knaek-manager')
        })
        .catch((error) => {
          swal.stopLoading();
          swal.close();
          handleError(error);
        });
      }
    });
  }


  async function handleInputChangeFile(e) {
    const {name, files} = e.target;
    const file = files[0];
    if (!file) return;
      const constraints = {
          logo: {
              maxSizeMB: 0.05,
              dimensions: { width: 250, height: 250 }
          },
          backgroundImage: {
              maxSizeMB: 0.25,
              dimensions: { width: 1024, height: 768 }
          }
      };
      const validFileTypes = ['image/jpg', 'image/jpeg', 'image/png'];
      const validateFileSize = (file, maxSizeMB) => {
          const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);
          return parseFloat(fileSizeMB) <= maxSizeMB;
      };

      // Utility function to validate image dimensions
      const validateImageDimensions = (img, { width, height }) => {
          return img.width === width && img.height === height;
      };

      if (!validFileTypes.includes(file.type.toLowerCase())) {
          toast.error("Image must be of png or jpg format")
          return false;
      }

      if (constraints[name] && !validateFileSize(file, constraints[name].maxSizeMB)) {
          toast.error(`Size must be equal or less than ${constraints[name].maxSizeMB * 1024}K`);
          return false;
      }


    const reader = new FileReader();

    // Function to run once the file is read
    reader.onload = function (upload) {
        const img = new Image();

        img.src = reader.result;

        img.onload = function() {
            if (constraints[name] && !validateImageDimensions(img, constraints[name].dimensions)) {
                toast.error(`Image dimensions must be: ${constraints[name].dimensions.width} * ${constraints[name].dimensions.height}`);
                return false;
            }
            // Set the state with the new image URL for preview
            setFormData(prevFormData => ({
                ...prevFormData,
                [`${name}`]: file,
            }));

            setFormImagePreview(prevFormData => ({
                ...prevFormData,
                [`${name}`]: upload.target.result,
            }));
        };
    };

    // Read the file as a data URL (base64)
    reader.readAsDataURL(file);

}

  async function handleInputChange(e) {
    const { name, type, checked } = e.target;
    let value;

    if (type === "checkbox") {
      value = checked;
    } else if (type === "file") {
      value = e.target.files[0];
    } else {
      value = e.target.value;
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  function validateUrl(event) {
      try {
          new URL(event.target.value)
          return true;
      }  catch (e) {
          swal('Not valid URL', 'Please enter a valid URL.', 'error');
          setFormData(prevState => ({
              ...prevState,
              website: ''
          }));
          event.preventDefault();
          return false;
      }
  }

  async function handleCompanySubmit(e) {
    e.preventDefault();
    if(!haveIdenticalKeys(formData, defaultFormValues)) console.warn('check sent formData keys not the same!!')

    try {
      setIsSubmiting(true);
      const formToSend = createFormData(formData);

      if(props.isUpdate && props?.companyId) {
        await updateCompanyCall(formToSend, props.companyId);
        await props?.trigerAfterSubmit?.() // refetch company details
      }else{
        await createCompanyCall(formToSend);
        setFormData(defaultFormValues);
      }

      props.setShowModal(false);
      toast.success(`Company ${props.isUpdate ? "Updated" : "Created"} Successfully!`);
      setIsSubmiting(false);
    } catch (err) {
      setIsSubmiting(false);
      handleError(err);
    }
  };


  async function fetchCategories(){
    try {
      const result = await getCategories();
      setCategories(result);
    } catch (err) {
      handleError(err);
    }
  }

  async function fetchSubscriptionTypes(){
    try {
      const result = await getSubscriptionTypes();
      setSubscriptionTypes(result);
    } catch (err) {
      handleError(err);
    }
  }

  async function fetchFilters() {
    try {
      const result = await fetchFilterProductionNotificationCall();
      setCities(result.cities)
      setAccountOwners(result.accountOwners.map(accountOwner=>({...accountOwner, label:accountOwner.value.replaceAll('_', ' ')})))
    } catch (err) {
      handleError(err);
    }
  }

  function createFormData(data) {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      // Ensure `cityIds` are formatted as a JSON string array
      if(key === 'cityIds'){
        formData.append(key, `"${JSON.stringify(value)}"`);
        return
      }
      formData.append(key, value);
    });
    return formData;
  }
}
