import { toastOptions } from "../config/Constants";
import { toast } from "react-toastify";
import { store } from "../redux/store";
import CryptoJS from "crypto-js";
import React from "react";

export function hasAValue(value) {
  if (value !== null && value !== undefined && value !== "") {
    return true;
  } else {
    return false;
  }
}

export function getLanguage() {
  // let language = getDataLocalStorage(appLocalStoargeValues.language)
  let language = undefined;
  if (!hasAValue(language)) {
    return "nl";
  } else {
    return language;
  }
}

export async function userLoggedIn() {
  const stateRedux = store.getState();
  let user_data = stateRedux.userData;
  if (hasAValue(user_data)) {
    return true;
  } else {
    return false;
  }
}

export function loginAgain(error) {
  if (parseInt(error.response.status) === 401) {
    toast.warn("Please login again", toastOptions);
    // window.location.reload();
  }
}

export function oddOrEven(number) {
  if (parseInt(number) % 2 === 0) {
    return "even";
  } else {
    return "odd";
  }
}

export function sortAllEducations(educations) {
  educations.sort(function (a, b) {
    return a.name - b.name;
  });
  return educations;
}

export function cellStyle(value) {
  if (value) {
    return <span className={"text-primary"}>{value}</span>;
  }
}

export function generateSignature(body, includeBody = true) {
  let timestamp = new Date().getTime().toString();
  const key = timestamp + process.env.REACT_APP_KNAEK_API_KEY;
  return {
    signature: CryptoJS.HmacSHA256(JSON.stringify(includeBody? body: {}), key).toString(),
    timestamp: timestamp,
  };
}

export function isValidEmail(email) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

export function humanDate(date) {
  if (!date) return;
  date = new Date(date);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}/${month}/${day}`;
}

export function DMYDate(date) {
  if (!date) return;
  date = new Date(date);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${day}/${month}/${year}`;
}

export function handleError(error) {
  if (error?.response?.data?.error) {
    toast.error(error?.response?.data?.error);
    return;
  }
  const errors = error?.response?.data?.payload?.message;
  if (Array.isArray(errors)) {
    errors.forEach((errorMessage) => {
      toast.error(errorMessage);
    });
  } else {
    toast.error(errors);
  }
}

export function getDateOver(currentDate) {
  // Split the input date string into components
  const [year, month, day] = currentDate?.split('-').map(num => parseInt(num, 10));

  // Construct a new Date object using local time
  // Note: Months are 0-indexed in JavaScript Date, hence the -1 on month
  const futureDate = new Date(year, month - 1, day);

  // Format year, month, and day with leading zeros if necessary
  const formattedYear = futureDate.getFullYear();
  const formattedMonth = ('0' + (futureDate.getMonth() + 1)).slice(-2);
  const formattedDay = ('0' + futureDate.getDate()).slice(-2);

  // Return the formatted date string and the Date object
  return { dateString: `${formattedYear}-${formattedMonth}-${formattedDay}`, dateRaw: futureDate };
}

export const transformDate = (dateString, justDate) => {
  if (!dateString) return "-";

  const formattedDate = new Date(dateString).toLocaleDateString("nl-NL", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  if (justDate) {
    return formattedDate;
  } else {
    const formattedTime = new Date(dateString).toLocaleTimeString("nl-NL");
    return `${formattedDate} ${formattedTime}`;
  }
};
export const CustomClearText = () => "clear all";
export const ClearIndicator = (props) => {
  const {
    children = <CustomClearText/>,
    getStyles,
    innerProps: {ref, ...restInnerProps},
  } = props;
  return (
      <div {...restInnerProps}
           ref={ref}
           style={getStyles("clearIndicator", props)}
      >
        <div style={{padding: "0px 5px"}}>{children}</div>
      </div>
  );
};

export const ClearIndicatorStyles = (base, state) => ({
  ...base,
  cursor: "pointer",
  color: state.isFocused ? "blue" : "black",
});

export const ControlStyles = (base) => ({
  ...base,
  border: `0.0625rem solid #ccc7c7`,
  'min-height': '3rem',
  'border-radius': '1rem'
});
export const MultiValueStyles = (base) => ({
  ...base,
  'border-radius': '.5rem'
});


export const getDiscountStatus = (startDate, endDate) => {
  const today = new Date();
  const start = new Date(startDate);
  const end = new Date(endDate);

  if (today < start) {
    return 'Not Live Yet';
  } else if (today >= start && today <= end) {
    return 'Live';
  } else {
    return 'Expired';
  }
};

export function haveIdenticalKeys(obj1, obj2) {
  const keys1 = Object.keys(obj1).sort();
  const keys2 = Object.keys(obj2).sort();
  
  return JSON.stringify(keys1) === JSON.stringify(keys2);
}

export function formatToDayNameString(dateString){

// Convert the string into a Date object
const date = new Date(dateString);

// Format the date (weekday, month, day, year)
const formattedDate = new Intl.DateTimeFormat('en-US', {
  weekday: 'long',  // 'Saturday'
  year: 'numeric',  // '2024'
  month: 'long',    // 'September'
  day: 'numeric'    // '17'
}).format(date);

// Format the time (12-hour format with AM/PM)
const formattedTime = date.toLocaleTimeString('en-US', {
  hour: 'numeric', 
  minute: 'numeric', 
  hour12: true  // 12-hour format with AM/PM
});

// Combine date and time
return `${formattedDate} - ${formattedTime}`;
}