export const LimitationType = {
    ORIENTATION: 'orientation',
    ALL: 'all',
    LIMITED: 'limited'
};
export const TargetGroupType = {
    MEMBERS: 'members',
};
export const NOTIFICATION_TYPES = {
    URL: 'url',
    INSTORE: 'instore',
    CASHBACK: 'cashback',
    JOB: 'jobs',
};