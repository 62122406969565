import React, {useEffect, useState} from "react";
import Select from "react-select";
import {hasAValue} from "../../../utils/SharedFunctions";
import {MoreInfo} from "../../MoreInfo";
import { TooltipMessagesTypes } from '../../../enums/tooltipMessagesTypes';
import PrevButton from "../../../pages/internalPages/Notification/PrevButton";

export default function Step3({
                                  setFilterSession,
                                  setFilterLimited,
                                  setFilterGender,
                                  setSelectedFiltersCities,
                                  selectedFiltersCities,
                                  goBack,
                                  goNextStep,
                                  country, setCountry,
                                  filterLimited, filterGender, filterSession, filterGroup,setFilterGroup, 
                                  filterAccountOwner,setFilterAccountOwner,
                                  orientationMode, setOrientationMode, productionFilters, 
                                  enableAccountOwner, setEnableAccountOwner, setEnableMode, enableMode
                              }) {

    const [productionFiltersCities, setProductionFiltersCities] = useState([])
    const [productionFiltersLimited, setProductionFiltersLimited] = useState([])
    const [productionFiltersGender, setProductionFiltersGender] = useState([])
    const [productionFiltersSession, setProductionFiltersSession] = useState([])
    const [productionFiltersGroup, setProductionFiltersGroup] = useState([])
    const [productionFilterAccountOwners, setProductionFilterAccountOwners] = useState([])
   
    useEffect(() => {
        setProductionFiltersLimited(productionFilters.limitationFilters)
        setProductionFiltersGender(productionFilters.genderFilters)
        setProductionFiltersSession(productionFilters.sessionFilters)
        setProductionFiltersGroup(productionFilters.targetGroups)
        setProductionFilterAccountOwners(productionFilters.accountOwners)
        let cities = productionFilters.cities.filter(item => parseInt(item.countryId) === parseInt(country))
        setProductionFiltersCities(cities)
    }, [productionFilters])
    useEffect(() => {
        if (hasAValue(productionFilters.cities)) {
            let cities = []
            if(country === '')
                cities = productionFilters.cities
            else{
                cities = productionFilters.cities.filter(item => parseInt(item.countryId) === parseInt(country))
            }
            setProductionFiltersCities(cities)
        }

    }, [country])


    const CustomClearText = () => "clear all";
    const ClearIndicator = (props) => {
        const {
            children = <CustomClearText/>,
            getStyles,
            innerProps: {ref, ...restInnerProps},
        } = props;
        return (
            <div {...restInnerProps}
                 ref={ref}
                 style={getStyles("clearIndicator", props)}
            >
                <div style={{padding: "0px 5px"}}>{children}</div>
            </div>
        );
    };

    const ClearIndicatorStyles = (base, state) => ({
        ...base,
        cursor: "pointer",
        color: state.isFocused ? "blue" : "black",
    });

    const handleModeChange = (orientationMode, enableAccountOwner) => {
        setOrientationMode(orientationMode)
        setEnableAccountOwner(enableAccountOwner)
        if(!enableAccountOwner)
            setFilterAccountOwner('')
        if(orientationMode)
        {
            setCountry('')
            setSelectedFiltersCities([])
            setFilterGender('')
            setFilterSession('')
            setFilterGroup('')
            setFilterAccountOwner('')
        }
    }
    const handleCountryChange = (event) => {
        const selectedValue = event.target.value;
        setCountry(selectedValue ? parseInt(selectedValue) : '');

        if (selectedValue) {
            setSelectedFiltersCities([]);
        }
    };
    return (
        <>
           <section>
                <div className="row justify-content-center">
                    <div className="col-lg-12 mb-2">
                        <div className="form-group">
                            <label className="text-label">Recipients Filter</label>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-start">
                    <div className="col-md-6 col-sm-10 mb-2">
                        <div className="form-group mb-3">
                            <label className="text-label">Country</label>
                            <select
                                disabled={orientationMode}
                                className="form-control form-control-md"
                                value={country ?? ""}
                                required
                                onChange={handleCountryChange}
                            >
                                <option value="">Both Countries</option>
                                <option value="1">Netherlands</option>
                                <option value="2">Belgium</option>
                            </select>
                        </div>
                    </div>

                    <div className="col-md-6 col-sm-10 mb-2">
                        <div className="form-group mb-3">
                            <label className="text-label">Cities</label>
                            <Select
                                isDisabled={orientationMode}
                                closeMenuOnSelect={false}
                                components={{ClearIndicator}}
                                styles={{clearIndicator: ClearIndicatorStyles}}
                                isMulti
                                value={selectedFiltersCities}
                                options={productionFiltersCities}
                                onChange={(cities) => {
                                    setSelectedFiltersCities(cities)
                                }}
                            />
                        </div>
                    </div>

                    <div className="col-md-6 col-sm-10 mb-2">
                        <div className="form-group mb-3">
                            <label className="text-label d-flex align-items-center ">
                                <span className="me-2">Mode</span>
                                <MoreInfo content={[TooltipMessagesTypes.ORIENTATION_MODE_NOTE]} />
                            </label>
                            <select
                                className="form-control form-control-md"
                                disabled={!enableMode}
                                value={filterLimited}
                                required
                                style={{textTransform: 'capitalize'}}
                                onChange={(val) => {
                              
                                    setFilterLimited(val.target.value ?? undefined);
                                    
                                    if(['orientation', 'all'].includes(val.target.value))
                                        handleModeChange(true, false)
                                    else if(val.target.value === 'limited')
                                        handleModeChange(false, false)
                                    else 
                                        handleModeChange(false, true)
                                }}
                            >
                                <option value={''}>Select Option</option>

                                {productionFiltersLimited.map((item, i)=>{
                                    if ( item.label === 'Limited_unlimited' )
                                        return(
                                            <option value={item.value}>{item.label.replaceAll('_', ' & ')}</option>
                                        )
                                    else
                                        return(
                                            <option value={item.value}>{item.label.replaceAll('_', ' ')}</option>
                                        )
                                })}
                            </select>
                        </div>
                    </div>

                    <div className="col-md-6 col-sm-10 mb-2">
                        <div className="form-group mb-3">
                            <label className="text-label">Gender</label>
                            <select
                                disabled={orientationMode}
                                className="form-control form-control-md"
                                value={filterGender}
                                required
                                onChange={(val) => {
                                    setFilterGender(val.target.value ?? undefined);
                                }}
                            >
                                <option value={''}>All Genders</option>

                                {productionFiltersGender.map((item, i)=>{
                                    return(
                                        <option value={item.value}>{item.label}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>

                    <div className="col-md-6 col-sm-10 mb-2">
                        <div className="form-group mb-3">
                            <label className="text-label">Session</label>
                            <select
                                disabled={orientationMode}
                                className="form-control form-control-md"
                                value={filterSession}
                                required
                                onChange={(val) => {
                                    setFilterSession(val.target.value ?? undefined);
                                }}
                            >
                                <option value={''}>Choose an option (optional)</option>

                                {productionFiltersSession.map((item, i)=>{
                                    return(
                                        <option value={item.value}>{item.label}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-10 mb-2">
                        <div className="form-group mb-3">
                            <label className="text-label d-flex align-items-center ">
                                <span className="me-2">Account Owner</span>
                                <MoreInfo content={[TooltipMessagesTypes.ACCOUNT_OWNER_MODE_NOTE]} />
                            </label>
                            <select
                                disabled={!enableAccountOwner}
                                className="form-control form-control-md"
                                value={filterAccountOwner}
                                required
                                onChange={(val) => {
                                    setFilterAccountOwner(val.target.value ?? undefined)
                                }}
                            >
                                <option value={''}>Both</option>

                                {productionFilterAccountOwners.map((item, i)=>{
                                    return(
                                        <option value={item.value}>{item.value?.replaceAll('_', ' ')}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12 col-sm-10 mb-2">
                        <div className="form-group mb-3 d-flex align-items-center">
                            <input type="checkbox" name="targetGroup" 
                                checked={filterGroup === 'members'}
                                required
                                onChange={(val) => {
                                    setFilterGroup(val.target.checked ? 'members' : undefined)
                                    setEnableMode(!val.target.checked)
                                    if(val.target.checked){
                                        setFilterLimited('')
                                        handleModeChange(false, true)
                                    }
                                }}
                                />
                            <label className="text-label mb-0 ">
                                <span className="ms-2">Only send to users with active subscription</span>
                            </label>
                        </div>
                                
                    </div>




                </div>
            </section>
            <section>
            </section>

            <div className="text-end toolbar toolbar-bottom p-2">
                <PrevButton onClick={goBack} />
                <button className="btn btn-primary sw-btn-next ms-1"
                        onClick={() => goNext()}>Next
                </button>
            </div>
        </>
    );

    function goNext() {
        goNextStep()
    }




};
