import { ColumnFilter } from './ColumnFilter';
import {cellStyle, hasAValue, DMYDate} from "../../utils/SharedFunctions";

const types = [
        {
            name: 'URL', value: 'url'
        },
        {
            name: 'Instore', value: 'instore'
        },
        {
            name: 'Online', value: 'cashback'
        },
        {
            name: 'Jobs', value: 'jobs'
        },
    ]
export const columns_notification = [
	{
		Header : 'Type',
		Footer : 'Type',
		accessor: 'type',
		Filter: ColumnFilter,
		Cell: ({ value }) => {
			return types.filter(item => { return item.value === value })[0]?.name
		},
		
	},
	{
		Header : 'Title (NL)',
		Footer : 'Title (NL)',
		accessor: 'notificationData.titleNl',
		Filter: ColumnFilter,
	},
	{
		Header : 'Body (NL)',
		Footer : 'Body (NL)',
		accessor: 'notificationData.bodyNl',
		Cell: ({ value }) => {return cellStyle(value)},
		Filter: ColumnFilter,
	},
	{
		Header : 'Made by',
		Footer : 'Made by',
		accessor: 'madeBy',
		Cell: ({ value }) => {return hasAValue(value)? value : "Unknown"},
		Filter: ColumnFilter,
	},
	{
		Header : 'Send Date',
		Footer : 'Send Date',
		accessor: 'sentDate',
		Cell: ({ value }) => { return value ? DMYDate(value) : '-'},
		Filter: ColumnFilter,
	},
	{
		Header : 'Status',
		Footer : 'Status',
		accessor: 'status',
		Filter: ColumnFilter,
		Cell: ({ value }) => {
			return value
		},
		
	},
]
