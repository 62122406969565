import React from "react";

const PrevButton = ({ onClick }) => {
    return (
        <button
            className="btn btn-secondary sw-btn-prev me-1"
            onClick={() => onClick()}>
            Prev
        </button>
    );
};

export default PrevButton;