import React,{ useState, useRef, useEffect } from 'react';
import { Tab} from 'react-bootstrap';
import AllNotification from "../../../components/Notifications/AllNotification";
const NotificationOverview = () =>{
    const [data, setData] = useState(
        document.querySelectorAll("#room_wrapper tbody tr")
    );
    const sort = 10;
    const activePag = useRef(0);


    // Active data
    const chageData = (frist, sec) => {
        for (var i = 0; i < data.length; ++i) {
            if (i >= frist && i < sec) {
                data[i].classList.remove("d-none");
            } else {
                data[i].classList.add("d-none");
            }
        }
    };
    // use effect
    useEffect(() => {
        setData(document.querySelectorAll("#room_wrapper tbody tr"));
    }, []);


    // Active pagginarion
    activePag.current === 0 && chageData(0, sort);
    return(
        <>
            <Tab.Container defaultActiveKey="All">
                <div className="card">
                    <div className="card-body p-0">
                        <Tab.Content>
                            <Tab.Pane eventKey="All">
                              <AllNotification/>
                            </Tab.Pane>

                        </Tab.Content>
                    </div>
                </div>

            </Tab.Container>
        </>
    )
}
export default NotificationOverview;
