import React, {Fragment, useEffect, useState} from "react";
import {useHistory} from 'react-router-dom';
import {Step, Stepper} from 'react-form-stepper';
import Step1 from "../../../components/Notifications/NewNotificationWizard/Step1";
import Step2 from "../../../components/Notifications/NewNotificationWizard/Step2";
import Step4 from "../../../components/Notifications/NewNotificationWizard/Step4";
import {appToastMessages, toastOptions} from "../../../config/Constants";
import {toast} from "react-toastify";
import {
    createAndSendNotificationCall,
    createNotificationCall,
    fetchFilterProductionNotificationCall,
    getNotificationDetailsCall,
    testNotificationCall,
    updateNotificationCall
} from "../../../Api/ApiCalls";
import swal from "sweetalert";
import Step3 from "../../../components/Notifications/NewNotificationWizard/Step3";
import Swal from "sweetalert2";
import {useParams} from "react-router-dom/cjs/react-router-dom";
import {LimitationType, TargetGroupType} from "../../../enums/NotificationData";
import {hideLoadingIndicator, showLoadingIndicator} from "../../../utils/loadingIndicator";


export default function NewNotification() {
    const history = useHistory();
    const [goSteps, setGoSteps] = useState(1);
    const [mode, setMode] = useState("production");
    const [type, setType] = useState("url");
    const [titleEn, setTitleEn] = useState(undefined);
    const [bodyEn, setBodyEn] = useState(undefined);
    const [titleNl, setTitleNl] = useState(undefined);
    const [bodyNl, setBodyNl] = useState(undefined);
    const [notificationItem, setNotificationItem] = useState(undefined);
    const [selectedFiltersForProductionMode, setSelectedFiltersForProductionMode] = useState(undefined)
    const [filterLimited, setFilterLimited] = useState(undefined)
    const [filterGender, setFilterGender] = useState(undefined)
    const [filterSession, setFilterSession] = useState(undefined)
    const [filterGroup, setFilterGroup] = useState(undefined)
    const [filterAccountOwner, setFilterAccountOwner] = useState(undefined)
    const [selectedFiltersCities, setSelectedFiltersCities] = useState([])
    const [orientationMode, setOrientationMode] = useState(false)
    const [enableAccountOwner, setEnableAccountOwner] = useState(true)
    const [enableMode, setEnableMode] = useState(true)
    const [country, setCountry] = useState(1)
    const [finalRecipients, setFinalRecipients] = useState(0)
    const [productionFilters, setProductionFilters] = useState([])
    const { notificationId } = useParams();
    const updateReceiversCount = (count) => {
        setFinalRecipients(count);
    };
    useEffect(() => {
        setSelectedFiltersForProductionMode({
            country: country,
            cities: selectedFiltersCities,
            limitation: filterLimited,
            gender: filterGender,
            session: filterSession,
            group: filterGroup,
            accountOwner: filterAccountOwner
        })
    }, [country, filterSession, filterLimited, filterGender, selectedFiltersCities, filterGroup, filterAccountOwner])

    useEffect(() => {
        if(notificationId)
        {
            getNotificationDetailsCall(notificationId)
            .then(result => {
                const countries = result.segmentationCriteria.countries;
                const segmentationCriteria = result.segmentationCriteria;
                const limitationType = result.segmentationCriteria.limitationType;
                setType(result.type)
                setFilterAccountOwner(segmentationCriteria.accountOwner)
                setFilterSession(segmentationCriteria.userSession)
                setFilterLimited(limitationType)
                setFilterGender(segmentationCriteria.Gender)
                setFilterGroup(segmentationCriteria.targetGroup)
                setTitleEn(result.notificationData.titleEn)
                setBodyEn(result.notificationData.bodyEn)
                setTitleNl(result.notificationData.titleNl)
                setBodyNl(result.notificationData.bodyNl)
                switch (limitationType) {
                    case LimitationType.ORIENTATION:
                    case LimitationType.ALL:
                        setOrientationMode(true);
                        setEnableAccountOwner(false)
                        break;
                    case LimitationType.LIMITED:
                        setEnableAccountOwner(false)
                        break;
                    default:
                        break;

                }
                if(segmentationCriteria.targetGroup === TargetGroupType.MEMBERS) {
                    setEnableMode(false)
                }

                if(countries.length > 0)
                {
                    const cities = countries.flatMap(country =>
                        country.cities.map(city => ({ label: city.name, value: city.id }))
                    );
                    setSelectedFiltersCities(cities)
                    setCountry(countries.length > 1 ? '' : countries[0].id)
                }
                else
                    setCountry('')

                const notificationItemData = result.type === "url"
                    ? { url: result.data.url }
                    : { id: result.data.id };

                setNotificationItem(notificationItemData);

            })
        }
    }, [notificationId])

    useEffect(() => {
        fetchFilterProductionNotification();
    }, [])
    function fetchFilterProductionNotification() {
        fetchFilterProductionNotificationCall().then(results => {
            setProductionFilters(results)
        }).catch(error => {
            toast.error(error.response.data.error, toastOptions);
        })
    }
    return (
        <Fragment>
            <div className="row justify-content-center">
                <div className="col-xl-12 col-xxl-12">
                    <div className="card">
                        <div className="card-header">
                            {goSteps === 1 && <h4 className="card-title">Notification info
                            </h4>}
                            {goSteps === 2 && <h4 className="card-title">Notification
                            </h4>}
                            {goSteps === 3 && <h4 className="card-title">Target
                            </h4>}
                            {goSteps === 4 && <h4 className="card-title">Control & send
                            </h4>}
                        </div>
                        <div className="card-body">

                            <div className="form-wizard ">
                                <Stepper className="nav-wizard" activeStep={goSteps - 1} label={false}>
                                    <Step className="nav-link" onClick={() => setGoSteps(1)}/>
                                    <Step className="nav-link" onClick={() => setGoSteps(2)}/>
                                    <Step className="nav-link" onClick={() => setGoSteps(3)}/>
                                    <Step className="nav-link" onClick={() => setGoSteps(4)}/>
                                </Stepper>


                                {goSteps === 1 &&
                                    <Step1
                                        type={type} setType={(item) => {
                                        setNotificationItem(undefined)
                                        setType(item)
                                    }}
                                        notificationId = {notificationId}
                                        goNextStep={() => goNextStep()}
                                        mode={mode} setMode={(item) => setMode(item)}
                                    />
                                }


                                {goSteps === 2 &&
                                    <Step2 type={type}
                                           titleEn={titleEn} setTitleEn={(item) => setTitleEn(item)}
                                           bodyEn={bodyEn} setBodyEn={(item) => setBodyEn(item)}
                                           titleNl={titleNl} setTitleNl={(item) => setTitleNl(item)}
                                           bodyNl={bodyNl} setBodyNl={(item) => setBodyNl(item)}
                                           notificationItem={notificationItem}
                                           setNotificationItem={(item) => setNotificationItem(item)}
                                           testNotification={(item) => testNotification(item)}
                                           goNextStep={() => goNextStep()}
                                           goBack={() => goBack()}
                                    />}


                                {goSteps === 3 &&
                                    <Step3
                                           notificationId = {notificationId}
                                           goNextStep={() => goNextStep()}
                                           goBack={() => goBack()}
                                           country={country}
                                           filterLimited={filterLimited}
                                           filterGender={filterGender}
                                           filterSession={filterSession}
                                           filterGroup={filterGroup}
                                           productionFilters={productionFilters}
                                           setFilterGroup={(value) => setFilterGroup(value)}
                                           filterAccountOwner={filterAccountOwner}
                                           setFilterAccountOwner={(value) => setFilterAccountOwner(value)}
                                           orientationMode={orientationMode}
                                           setOrientationMode={(value) => setOrientationMode(value)}
                                           enableAccountOwner={enableAccountOwner}
                                           setEnableAccountOwner={(value) => setEnableAccountOwner(value)}
                                           enableMode={enableMode}
                                           setEnableMode={(value) => setEnableMode(value)}
                                           selectedFiltersCities={selectedFiltersCities}
                                           setSelectedFiltersCities={(value) => setSelectedFiltersCities(value)}
                                           setCountry={(value) => setCountry(value)}
                                           setFilterLimited={(value) => setFilterLimited(value)}
                                           setFilterGender={(value) => setFilterGender(value)}
                                           setFilterSession={(value) => setFilterSession(value)}
                                    />

                                }

                                {goSteps === 4 &&
                                    <Step4
                                        notificationId = {notificationId}
                                        type={type}
                                        titleEn={titleEn}
                                        bodyEn={bodyEn}
                                        titleNl={titleNl}
                                        bodyNl={bodyNl}
                                        selectedFiltersForProductionMode={selectedFiltersForProductionMode}
                                        notificationItem={notificationItem}
                                        goBack={() => goBack()}
                                        saveNotification={() => saveNotification()}
                                        saveAndSendNotification={() => createAndSendNotification()}
                                        onUpdateReceiversCount={updateReceiversCount}
                                    />

                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
    function goNextStep() {
        setGoSteps(goSteps + 1)
    }

    function goBack() {
        setGoSteps(goSteps - 1)
    }

    async function saveNotification(status = 'draft') {
        const data = {
            type: type,
            notificationData: {
                titleEn: titleEn,
                bodyEn: bodyEn,
                titleNl: titleNl,
                bodyNl: bodyNl,
                status: status,
            },
            notificationItem: {
                url: notificationItem.url,
                actionId: notificationItem.action?.actionId,
                iconId: notificationItem.action?.iconId,
                storeId: notificationItem.store?.id,
                jobId: notificationItem.job?.id,
                logo: notificationItem.store?.logo ?? notificationItem.job?.logo,
            },
            usersFilter: {
                countryId: parseInt(country),
                cityIds: selectedFiltersCities.map(city => city.value),
                limitation: filterLimited === '' ? undefined : filterLimited,
                gender: filterGender === '' ? undefined : filterGender,
                session: filterSession === '' ? undefined : filterSession,
                targetGroup: filterGroup === '' ? undefined : filterGroup,
                accountOwner: filterAccountOwner === '' ? undefined : filterAccountOwner,
            },
        }
        const confirmation = await swal({
            title: "Are you sure?",
            text: notificationId ? "You want to update this message!" : "You want to create this message!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        });

        if (!confirmation) {
            toast.warn(appToastMessages.notification_has_not_been_sent, toastOptions);
            return;
        }
        showLoadingIndicator()
        try {
            let result ;
            if (notificationId) {
                 result = await updateNotificationCall(notificationId, data)
            }else {
                 result = await createNotificationCall(data)
            }
            if (result && result.id) {
                setFinalRecipients(result.totalReceivers);
                const successMessage = notificationId
                    ? "The notification was updated successfully"
                    : `The notification with id "${result.id}" has been created successfully`;
                toast.success(successMessage, toastOptions);
                history.push('/notification-overview');
            }else {
                const errorMessage = notificationId
                    ? "An error occurred during the update!"
                    : "No recipients found for the selected criteria!";
                setFinalRecipients(0)
                toast.error(errorMessage, toastOptions);
            }
        } catch (error) {
            toast.error(`An error occurred! ${error}`, toastOptions);
        } finally {
            hideLoadingIndicator();
        }
    }
    function prepareNotificationData(status) {
        return {
            type: type,
            notificationData: {
                titleEn: titleEn,
                bodyEn: bodyEn,
                titleNl: titleNl,
                bodyNl: bodyNl,
                status: status,
            },
            notificationItem: {
                url: notificationItem.url,
                actionId: notificationItem.action?.actionId,
                iconId: notificationItem.action?.iconId,
                storeId: notificationItem.store?.id,
                jobId: notificationItem.job?.id,
                logo: notificationItem.store?.logo ?? notificationItem.job?.logo,
            },
            usersFilter: {
                countryId: parseInt(country),
                cityIds: selectedFiltersCities.map(city => city.value),
                limitation: filterLimited === '' ? undefined : filterLimited,
                gender: filterGender === '' ? undefined : filterGender,
                session: filterSession === '' ? undefined : filterSession,
                targetGroup: filterGroup === '' ? undefined : filterGroup,
                accountOwner: filterAccountOwner === '' ? undefined : filterAccountOwner,
            },
        };
    }
    async function createAndSendNotification() {
        const data = prepareNotificationData('sent')
        try {
            const userConfirmation = await swal({
                title: "Are you sure?",
                text: `You are about to send this notification to ${finalRecipients} users.`,
                icon: "warning",
                buttons: true,
                dangerMode: true,
            });
            if(!userConfirmation)
                return;
            showLoadingIndicator()
            const result = await createAndSendNotificationCall(data);
            if (result?.id) {
                setFinalRecipients(result.totalReceivers);
                toast.success(`Notification sent successfully! ID: "${result.id}", recipients: ${result.totalReceivers}.`, toastOptions);
                history.push('/notification-overview');
            } else {
                toast.error("No recipients found for the selected criteria.", toastOptions);
                setFinalRecipients(0);
            }

        } catch (error) {
            toast.error("An error occurred while sending the notification. Please try again.", toastOptions);
        }finally {
            hideLoadingIndicator();
        }
    }
    async function testNotification() {
        const data = {
            type: type,
            notificationData: {
                titleEn: titleEn,
                bodyEn: bodyEn,
                titleNl: titleNl,
                bodyNl: bodyNl,
            },
            notificationItem: {
                url: notificationItem?.url,
                actionId: notificationItem?.action?.actionId,
                iconId: notificationItem?.action?.iconId,
                storeId: notificationItem?.store?.id,
                jobId: notificationItem?.job?.id,
                logo: notificationItem?.store?.logo ?? notificationItem?.job?.logo,
            },
        }
        const confirmation =  await swal({
            title: "Are you sure?",
            text: "You want to test the notification !",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        });
        
        if (confirmation)
        {
            Swal.fire({
                title: "Loading...",
                allowOutsideClick: true,
                allowEscapeKey: false,
                showConfirmButton: false,
                icon: "info",
                onBeforeOpen: () => {
                    Swal.showLoading()
                },
            });
            
            testNotificationCall(data).then(notification => {
                Swal.close();
                toast.success("Test notification sent !", toastOptions);

            }).catch(error => {
                Swal.close();
                toast.warn("Test notification not sent !", toastOptions);
            })

        }
    }
};
