import React, {useEffect, useState} from "react";
import {
    getInStoreDiscountsCall,
    getJobActionsCall,
    getOnlineActionsCall
} from "../../../Api/ApiCalls";
import {toast} from "react-toastify";
import {
    appUrls, NotificationToastMessages,
    toastOptions,
} from "../../../config/Constants";
import PerfectScrollbar from "react-perfect-scrollbar";
import {hasAValue} from "../../../utils/SharedFunctions";
import PrevButton from "../../../pages/internalPages/Notification/PrevButton";
import * as Yup from 'yup';
import {ErrorMessage, Field, Form, Formik} from "formik";
import NotificationItem from "../components/NotificationItem";
import {NOTIFICATION_TYPES} from "../../../enums/NotificationData";

export default function Step2({
                                  type,
                                  notificationItem,
                                  setNotificationItem,
                                  bodyEn,
                                  setBodyEn,
                                  titleEn,
                                  setTitleEn,
                                  goBack,
                                  goNextStep,
                                  titleNl,
                                  setTitleNl,
                                  bodyNl,
                                  setBodyNl,
                                  testNotification
                              }) {
    const [actions, setActions] = useState([]);
    const [actionsFiltered, setActionsFiltered] = useState([]);

    const validationSchema = Yup.object().shape({
        titleEn: Yup.string().required('The English title is required'),
        bodyEn: Yup.string()
            .max(200, 'Body must be 200 characters or less')
            .required('The English Body is required'),
        titleNl: Yup.string().required('The Dutch title is required'),
        bodyNl: Yup.string()
            .max(200, 'Body must be 200 characters or less')
            .required('The Dutch Body is required'),
        ...(type.toString().includes('url') && {
            url: Yup.string().url('Invalid URL').required('URL is required'),
        }),
    });
    const initialValues = {
        url: notificationItem?.url || '',
        instore: '',
        cashback: '',
        titleEn: titleEn || '',
        bodyEn: bodyEn || '',
        titleNl: titleNl || '',
        bodyNl: bodyNl || '',
    };
    useEffect(() => {
        if (type.toString().includes(NOTIFICATION_TYPES.INSTORE)) {
            getInStoreActions();
        } else if (type.toString().includes(NOTIFICATION_TYPES.CASHBACK)) {
            getOnlineActions();
        } else if (type.toString().includes(NOTIFICATION_TYPES.JOB)) {
            getJobsActions();
        }
    }, []);

    return (
        <>
            <section>
                <div className="row justify-content-center">
                    <div className="mb-2">
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting(false)
                                goNext();
                            }}>
                            {({ setFieldValue, handleSubmit,setTouched, validateForm,errors, values }) => (
                                <Form>
                                    <div className="d-flex" style={{gap: "100px"}}>
                                        <div className="w-50">
                                            <div className="p-1" style={{
                                                textAlign: "center",
                                                fontSize: "20px"
                                            }}>English
                                            </div>
                                            <div className="form-group mb-3">
                                                <label className="text-label">Title (EN)*</label>
                                                <Field
                                                    type="text"
                                                    name="titleEn"
                                                    className={`form-control mb-3`}
                                                    defaultValue={titleEn}
                                                    value={values.titleEn}
                                                    onChange={(val) => {
                                                        setTitleEn(val.target.value);
                                                        setFieldValue('titleEn', val.target.value);
                                                    }}
                                                />
                                                <ErrorMessage name="titleEn" component="div" className="text-danger" />
                                            </div>
                                            <div className="form-group mb-3">
                                                <div className={"d-flex justify-content-between"}>
                                                    <label className="text-label">Body (EN)*</label>
                                                    <label className="text-label">
                                                        200/{hasAValue(bodyEn) ? bodyEn.toString().length : 0}
                                                    </label>
                                                </div>

                                                <Field
                                                    as="textarea"
                                                    name="bodyEn"
                                                    rows={4}
                                                    maxLength={200}
                                                    className={`form-control mb-3`}
                                                    defaultValue={bodyEn}
                                                    onChange={(val) => {
                                                        setBodyEn(val.target.value);
                                                        setFieldValue('bodyEn', val.target.value);
                                                    }}
                                                />
                                                <ErrorMessage name="bodyEn" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                        <div className="w-50">
                                            <div className="p-1" style={{
                                                textAlign: "center",
                                                fontSize: "20px"
                                            }}>Dutch
                                            </div>

                                            <div className="form-group mb-3">
                                                <label className="text-label">Title (NL)*</label>
                                                <Field
                                                    type="text"
                                                    name="titleNl"
                                                    className="form-control mb-3"
                                                    defaultValue={titleNl}
                                                    onChange={(val) => {
                                                        setTitleNl(val.target.value);
                                                        setFieldValue('titleNl', val.target.value);
                                                    }}
                                                />
                                                <ErrorMessage name="titleNl" component="div" className="text-danger" />
                                            </div>
                                            <div className="form-group mb-3">
                                                <div className={"d-flex justify-content-between"}>
                                                    <label className="text-label">Body (NL)*</label>
                                                    <label className="text-label">
                                                        200/{hasAValue(bodyEn) ? bodyEn.toString().length : 0}
                                                    </label>
                                                </div>

                                                <Field
                                                    as="textarea"
                                                    name="bodyNl"
                                                    rows={4}
                                                    maxLength={200}
                                                    className="form-control mb-3"
                                                    defaultValue={bodyNl}
                                                    onChange={(val) => {
                                                        setBodyNl(val.target.value);
                                                        setFieldValue('bodyNl', val.target.value);
                                                    }}
                                                />
                                                <ErrorMessage name="bodyNl" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    {/*url type*/}
                                    {type.toString().includes("url") && (
                                        <div className="form-group mb-3">
                                            <label className="text-label">URL*</label>
                                            <Field
                                                type="text"
                                                name="url"
                                                className="form-control mb-3"
                                                defaultValue={notificationItem?.url}
                                                onChange={(val) => {
                                                    setNotificationItem({ url: val.target.value });
                                                    setFieldValue('url', val.target.value);
                                                }}
                                                placeholder="https://www.knaek.nl/"
                                            />
                                            <ErrorMessage name="url" component="div" className="text-danger" />
                                        </div>
                                    )}

                                    {/*instore type*/}
                                    {type.toString().includes("instore") && (
                                        <div className="form-group mb-3">
                                            <label className="text-label">Instore Discount*</label>
                                            <div className="form-group mb-3">
                                                <div className="card border-0 pb-0">
                                                    <div className="card-body border-top shadow rounded">
                                                        {notificationItem && (
                                                            <NotificationItem
                                                                imagePath={appUrls.backend_api_image}
                                                                iconId={notificationItem.action?.iconId}
                                                                title={notificationItem.action?.companyName}
                                                                subtitle={notificationItem.action?.localization.nl.subtitle}
                                                            />
                                                        )}
                                                        <Field
                                                            type="text"
                                                            name="instore"
                                                            className="form-control mb-2"
                                                            onChange={(val) => {
                                                                setFieldValue('instore', val.target.value);
                                                                handleInputSearchItemInStore(val.target.value);
                                                            }}
                                                        />
                                                        <ErrorMessage name="instore" component="div" className="text-danger" />
                                                        <PerfectScrollbar
                                                            style={{height: "370px"}}
                                                            id="DZ_W_Todo2"
                                                            className="widget-media overflow-auto height370 ps ps--active-y"
                                                        >
                                                            <ul className="timeline">
                                                                {actionsFiltered.map((item, i) => {
                                                                    let selected = false;
                                                                    if (item === notificationItem) {
                                                                        selected = true;
                                                                    }
                                                                    return (
                                                                        <li
                                                                            key={i}
                                                                            className={"cursor-pointer"}
                                                                            onClick={() => setNotificationItem({action: item})}
                                                                        >
                                                                            <div className={"timeline-panel"}>
                                                                                <div className="media me-2">
                                                                                    <img
                                                                                        alt=""
                                                                                        width="50"
                                                                                        src={
                                                                                            appUrls.backend_api_image + item.iconId
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div className="media-body">
                                                                                    <h5 className="mb-1">{item.companyName}</h5>
                                                                                    <small className="d-block">
                                                                                        {item.localization.nl.subtitle}
                                                                                    </small>
                                                                                </div>
                                                                                {selected && (
                                                                                    <h3>
                                                                                        <i className="fas fa-check-circle"></i>
                                                                                    </h3>
                                                                                )}
                                                                            </div>
                                                                        </li>
                                                                    );
                                                                })}
                                                            </ul>
                                                        </PerfectScrollbar>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {/*online type*/}

                                    {type.toString().includes("cashback") && (
                                        <div className="form-group mb-3">
                                            <label className="text-label">Online Discount*</label>
                                            <div className="form-group mb-3">
                                                <div className="card border-0 pb-0">
                                                    <div className="card-body border-top shadow rounded">
                                                        {notificationItem && (
                                                            <div className="widget-media mb-2">
                                                                <ul className="timeline">
                                                                    <li className={"cursor-pointer"}>
                                                                        <div className={"timeline-panel"}>
                                                                            <div className="media me-2">
                                                                                <img
                                                                                    alt=""
                                                                                    width="50"
                                                                                    src={notificationItem.store?.logo}
                                                                                />
                                                                            </div>
                                                                            <div className="media-body">
                                                                                <h5 className="mb-1">
                                                                                    {notificationItem.store?.title}
                                                                                </h5>
                                                                                <small className="d-block">
                                                                                    {notificationItem.store?.en.shortDes}
                                                                                </small>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        )}
                                                        <input
                                                            type="text"
                                                            name="body"
                                                            className="form-control mb-2"
                                                            onChange={(val) => {
                                                                handleInputSearchItemOnline(val.target.value);
                                                            }}
                                                        />
                                                        <PerfectScrollbar
                                                            style={{height: "370px"}}
                                                            id="DZ_W_Todo2"
                                                            className="widget-media overflow-auto height370 ps ps--active-y"
                                                        >
                                                            <ul className="timeline">
                                                                {actionsFiltered.map((item, i) => {
                                                                    let selected = false;
                                                                    if (item === notificationItem) {
                                                                        selected = true;
                                                                    }
                                                                    return (
                                                                        <li
                                                                            key={i}
                                                                            className={"cursor-pointer"}
                                                                            onClick={() => setNotificationItem({store: item})}
                                                                        >
                                                                            <div className={"timeline-panel"}>
                                                                                <div className="media me-2">
                                                                                    <img
                                                                                        alt=""
                                                                                        width="50"
                                                                                        src={item.logo}
                                                                                    />
                                                                                </div>
                                                                                <div className="media-body">
                                                                                    <h5 className="mb-1">
                                                                                        {item.title}
                                                                                    </h5>
                                                                                    <small className="d-block">
                                                                                        {item.en.shortDes}
                                                                                    </small>
                                                                                </div>
                                                                                {selected && (
                                                                                    <h3>
                                                                                        <i className="fas fa-check-circle"></i>
                                                                                    </h3>
                                                                                )}
                                                                            </div>
                                                                        </li>
                                                                    );
                                                                })}
                                                            </ul>
                                                        </PerfectScrollbar>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {/* Jobs type */}
                                    {type.toString().includes("jobs") && (
                                        <div className="form-group mb-3">
                                            <label className="text-label">Jobs Discount*</label>
                                            <div className="form-group mb-3">
                                                <div className="card border-0 pb-0">
                                                    <div className="card-body border-top shadow rounded">
                                                        {notificationItem && (
                                                            <div className="widget-media mb-2">
                                                                <ul className="timeline">
                                                                    <li className={"cursor-pointer"}>
                                                                        <div className={"timeline-panel"}>
                                                                            <div className="media me-2">
                                                                                <img
                                                                                    alt=""
                                                                                    width="50"
                                                                                    src={notificationItem.job?.logo}
                                                                                />
                                                                            </div>
                                                                            <div className="media-body">
                                                                                <h5 className="mb-1">
                                                                                    {notificationItem.job?.jobTitle}
                                                                                </h5>
                                                                                <small className="d-block">
                                                                                    {notificationItem.job?.featured}
                                                                                </small>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        )}
                                                        <input
                                                            type="text"
                                                            name="body"
                                                            className="form-control mb-2"
                                                            onChange={(val) => {
                                                                handleInputSearchItemOnline(val.target.value);
                                                            }}
                                                        />
                                                        <PerfectScrollbar
                                                            style={{height: "370px"}}
                                                            id="DZ_W_Todo2"
                                                            className="widget-media overflow-auto height370 ps ps--active-y">
                                                            <ul className="timeline">
                                                                {actionsFiltered.map((item, i) => {
                                                                    let selected = false;
                                                                    if (item === notificationItem) {
                                                                        selected = true;
                                                                    }
                                                                    return (
                                                                        <li
                                                                            key={i}
                                                                            className={"cursor-pointer"}
                                                                            onClick={() => setNotificationItem({job: item})}
                                                                        >
                                                                            <div className={"timeline-panel"}>
                                                                                <div className="media me-2">
                                                                                    <img
                                                                                        alt=""
                                                                                        width="50"
                                                                                        src={item.logo}
                                                                                    />
                                                                                </div>
                                                                                <div className="media-body">
                                                                                    <h5 className="mb-1">
                                                                                        {item.jobTitle}
                                                                                    </h5>
                                                                                    <small className="d-block">
                                                                                        {item.featured}
                                                                                    </small>
                                                                                </div>
                                                                                {selected && (
                                                                                    <h3>
                                                                                        <i className="fas fa-check-circle"></i>
                                                                                    </h3>
                                                                                )}
                                                                            </div>
                                                                        </li>
                                                                    );
                                                                })}
                                                            </ul>
                                                        </PerfectScrollbar>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="d-flex align-items-center justify-content-end toolbar toolbar-bottom p-2">
                                        <button
                                            className="btn btn-primary sw-btn-prev me-auto"
                                            onClick={(e) => {
                                                //TODO: Should improve it
                                                e.preventDefault();
                                                validateForm().then((errors) => {
                                                    if (Object.keys(errors).length === 0) {
                                                        testNotification(values);
                                                    } else {
                                                        setTouched(
                                                            Object.keys(errors).reduce((acc, field) => {
                                                                acc[field] = true;
                                                                return acc;
                                                            }, {})
                                                        );
                                                    }
                                                });
                                            }}
                                        >
                                            Test Notification
                                        </button>
                                        <PrevButton onClick={goBack} />
                                        <button
                                            type="button"
                                            className="btn btn-primary sw-btn-next ms-1"
                                            onClick={() => validateForm(errors).then(() => {
                                                    handleSubmit();
                                            })}>
                                            Next
                                        </button>
                                    </div>
                            </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </section>

        </>
    );


    function goNext() {
        // Determine the error message based on the type
        const errorMessages = {
            [NOTIFICATION_TYPES.URL]: NotificationToastMessages.job_required,
            [NOTIFICATION_TYPES.INSTORE]: NotificationToastMessages.instore_required,
            [NOTIFICATION_TYPES.CASHBACK]: NotificationToastMessages.cashback_required,
            [NOTIFICATION_TYPES.JOB]: NotificationToastMessages.job_required,
        };
        const errorMessage = !notificationItem ? errorMessages[type] : null;
        if (errorMessage) {
            return toast.error(errorMessage, toastOptions);
        }
        goNextStep();
    }

    function getInStoreActions() {
        getInStoreDiscountsCall()
            .then((results) => {
                setActions(results);
                setActionsFiltered(results);
                if(notificationItem?.id)
                {
                    const item = results.filter(result => { return result.actionId == notificationItem.id })[0]
                    setNotificationItem({action : item})
                }
            })
            .catch((error) => {
                toast.error(error.response.data.error, toastOptions);
            });
    }

    function getOnlineActions(data = '') {
        getOnlineActionsCall(data)
            .then((results) => {
                setActions(results.items);
                setActionsFiltered(results.items);
                if(notificationItem?.id)
                {
                    const item = results.items.filter(result => { return result.id == notificationItem.id })[0]
                    setNotificationItem({store : item})
                }
            })
            .catch((error) => {
                toast.error(error.response.data.error, toastOptions);
            });
    }

    function getJobsActions() {
        getJobActionsCall()
            .then((results) => {
                setActions(results);
                setActionsFiltered(results);
                if(notificationItem?.id)
                {
                    const item = results.filter(result => { return result.id == notificationItem.id })[0]
                    setNotificationItem({job : item})
                }
            })
            .catch((error) => {
                toast.error(error.response.data.error, toastOptions);
            });
    }

    function handleInputSearchItemInStore(searchValue) {
        setActionsFiltered(actions);
        let filteredData = actions.filter((element) => {
            return (
                (element.companyName
                        .toLowerCase()
                        .includes(searchValue.toLowerCase()) ||
                    element.localization.nl.subtitle
                        .toLowerCase()
                        .includes(searchValue.toLowerCase())) &&
                (element.companyName
                        .toLowerCase()
                        .includes(searchValue.toLowerCase()) ||
                    element.localization.nl.subtitle
                        .toLowerCase()
                        .includes(searchValue.toLowerCase()))
            );
        });
        setActionsFiltered(filteredData);
    }

    function handleInputSearchItemOnline(searchValue) {
        getOnlineActions(searchValue)
    }
}
