import React from 'react';
import { useEditor, EditorContent} from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from "@tiptap/extension-underline";

const RichTextEditor = ({ onChange, initialContent, label }) => {
    const editor = useEditor({
        extensions: [
          StarterKit,
          Underline,
        ],
    
        onCreate:(e) => {
            e.editor.commands.setContent(initialContent)
        },

        onUpdate: (e) => {
            onChange(e.editor.getHTML())
        },
      });

    return (
        <div>
            <label className="text-black font-w500">{label}</label>
            <div style={{ border: '1px solid #ddd', padding: '0px', paddingTop: '0px', paddingBottom: '10px', height: '365px' }} className={"form-control"}>
                <Toolbar editor={editor} />
                <div style={{ padding: '10px', height: '300px',overflowY: 'auto' }}>
                    <EditorContent editor={editor} />
                </div>
            </div>
        </div>
    );
};

const Toolbar = ({ editor }) => {
    return (
      <div className="toolbar-rich-text-editor">
          {/* Paragraph/Headings */}
          <StyleButton
              active={editor.isActive("heading", { level: 1 })}
              label='H1'
              onToggle={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
          />
          <StyleButton
              active={editor.isActive("heading", { level: 2 })}
              label='H2'
              onToggle={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
          />

        {/* List */}
        <StyleButton
            active={editor.isActive("bulletList")}
            label='UL'
            onToggle={() => editor.chain().focus().toggleBulletList().run()}
        />
        <StyleButton
            active={editor.isActive("orderedList")}
            label='OL'
            onToggle={() => editor.chain().focus().toggleOrderedList().run()}
        />

        <StyleButton
            active={editor.isActive("bold")}
            label='Bold'
            onToggle={() => editor.chain().focus().toggleBold().run()}
        />
        <StyleButton
            active={editor.isActive("underline")}
            label='Underline'
            onToggle={() => editor.chain().focus().toggleUnderline().run()}
        />
  
      </div>
    );
  };

const StyleButton = ({ active, label, onToggle, style }) => {
    const handleMouseDown = (e) => {
        e.preventDefault();
        onToggle(style);
    };

    let className = 'toolbar-button-rich-text-editor';
    if (active) {
        className += ' active';
    }

    return (
        <span className={className} onMouseDown={handleMouseDown}>
            {label}
        </span>
    );
};

export default RichTextEditor