import React, {useEffect, useState} from "react";
import {useFilters, useGlobalFilter, usePagination, useSortBy, useTable,} from "react-table";
import {Modal} from "react-bootstrap";
import {locations_column_name} from "../../../../components/FilteringTable/Columns";
import {
  createLocationForCompany,
  deleteCompanyLocation,
  getLocationsByCompanyId,
  updateLocationCall,
} from "../../../../Api/ApiCalls";
import {handleError} from "../../../../utils/SharedFunctions";
import {ApiSearchField} from "../../../../components/ApiSearchField";
import {toast} from "react-toastify";
import {MoreInfo} from "../../../../components/MoreInfo";
import { TooltipMessagesTypes } from '../../../../enums/tooltipMessagesTypes';

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export default function Locations({companyId}) {
  const [isLoading, setIsLoading] = useState(false);

  const [columns, setColumns] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [locations, setLocations] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [targetPage, setTargetPage] = useState(0);
  const [formData, setFormData] = useState({
    address: "",
    phoneNumber: "",
    city: "",
    locationId: "",
    postalCode: "",
    latitude: null,
    longitude: null,
  });

  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);


  useEffect(() => {
    setColumns(locations_column_name);
  }, []);


  useEffect(() => {
    getLocations(1, companyId);
  }, [debouncedSearchQuery, companyId]);



  const tableInstance = useTable(
    {
      columns,
      data: locations,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page
  } = tableInstance;






  return (
    <>
      <div className="col-xl-12 col-xxl-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Locations</h4>
            <a className="btn btn-secondary" onClick={() => openModalAdd()}>
              + Add location
            </a>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <div className={`d-flex flex-column mb-2 w-25`} style={{}}>
                <ApiSearchField
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                />
              </div>
              {" "}
              <table {...getTableProps()} className="table dataTable display">
                <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                          <th
                          >
                            {column.render("Header")}
                          </th>
                      ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell, index) => {
                          return (
                              <td
                                  key={index}
                                  {...cell.getCellProps()}
                                  className={"cursor-pointer"}
                                  onClick={() => openModalEdit(row.original)}
                              >
                                {cell.render("Cell")}
                              </td>
                          );
                        })}
                      </tr>
                  );
                })}
                </tbody>
              </table>
              {/* This is only for footer if u require */}
              <div className="d-flex justify-content-between">
            <span>
              Page{" "}
              <strong>
                {locations.length === 0
                    ? "0 of 0"
                    : currentPage + " of " + totalPages}
              </strong>
              {""}
            </span>
                <span className="table-index d-none">
              Go to page :{" "}
                  <input
                      type="number"
                      className="ml-2"
                      value={targetPage}
                      onChange={(e) => {
                        setTargetPage(e.target.value);
                        getLocations(Number(e.target.value), companyId);
                      }}
                  />
            </span>
              </div>
              <div className="text-center mb-3">
                <div className="filter-pagination  mt-3">
                  <button className=" previous-button" onClick={() => {
                    if (locations.length === 0)
                      return;
                    getLocations(1, companyId);
                  }}
                          disabled={currentPage === 1 || locations.length === 0}
                  >
                    {"<<"}
                  </button>

                  <button className="previous-button" onClick={() => {
                    if (locations.length === 0)
                      return;
                    getLocations(currentPage - 1, companyId);
                  }}
                          disabled={currentPage === 1 || locations.length === 0}
                  >
                    Previous
                  </button>
                  <button className="next-button" onClick={() => {
                    if (locations.length === 0)
                      return;
                    getLocations(targetPage, companyId);
                  }}
                          disabled={currentPage === totalPages || locations.length === 0}
                  >
                    Next
                  </button>
                  <button className=" next-button" onClick={() => {
                    if (locations.length === 0)
                      return;
                    getLocations(totalPages, companyId);
                  }}
                          disabled={currentPage === totalPages || locations.length === 0}
                  >
                    {">>"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <Modal
          className="modal fade"
          size={"xl"}
          show={showModal}
          onHide={setShowModal}
      >
        <div className="" role="document">
          <div className="">
            <form>
              <div className="modal-header">
                <h4 className="modal-title fs-20"> {showEdit ? "Update Location" : "Add Location"} </h4>
                <button
                    type="button"
                    className="btn-close"
                    onClick={() => setShowModal(false)}
                    data-dismiss="modal"
                ></button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className={"row"}>
                      <div className="form-group mb-3 col-sm-10 col-md-6">
                        <label className="text-black font-w500">Address*</label>
                        <div className="contact-name">
                          <input
                              type="text"
                              className="form-control"
                              name="address"
                              required="required"
                              value={formData.address}
                              onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3 col-sm-10 col-md-6">
                        <label className="text-black font-w500">
                          Postal Code*
                        </label>
                        <div className="contact-name">
                          <input
                            type="text"
                            className="form-control"
                            name="postalCode"
                            required="required"
                            value={formData.postalCode}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3 col-sm-10 col-md-6">
                        <label className="text-black font-w500">City*</label>
                        <div className="contact-name">
                          <input
                            type="text"
                            className="form-control"
                            name="city"
                            required="required"
                            value={formData.city}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3 col-sm-10 col-md-6">
                        <label className="text-black font-w500 d-flex align-items-center">
                          Latitude
                          <MoreInfo content={[TooltipMessagesTypes.LATITUDE_EXAMPLE, TooltipMessagesTypes.ONLY_NUMBERS_NO_CHARACHTERS_SYMBOLS]}/>
                        </label>
                        <div className="contact-name">
                          <input
                              placeholder={"51.591675041964166"}
                              type="number"
                              className="form-control"
                              name="latitude"
                              required="required"
                              value={formData.latitude}
                              onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3 col-sm-10 col-md-6">
                        <label className="text-black font-w500 d-flex align-items-center">
                          Longitude
                          <MoreInfo content={[TooltipMessagesTypes.LONGTITUDE_EXAMPLE, TooltipMessagesTypes.ONLY_NUMBERS_NO_CHARACHTERS_SYMBOLS]}/>
                        </label>
                        <div className="contact-name">
                          <input
                              placeholder={"4.784625708463083"}
                              type="number"
                              className="form-control"
                              name="longitude"
                              required="required"
                              value={formData.longitude}
                              onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!showEdit && (
                  <div className="modal-footer">
                    <a className={`btn btn-secondary ${isLoading? 'disabled' : ''}`} onClick={() => addLocation()}>
                      Add
                    </a>
                    <a className="btn btn-warning" onClick={() => setShowModal(false)}>
                      Discard
                    </a>
                  </div>
              )}

              {showEdit && (
                  <div className="modal-footer">
                    <a className={`btn btn-secondary ${isLoading? 'disabled' : ''}`} onClick={() => updateLocation()}>
                      Save
                    </a>
                    <a className="btn btn-warning" onClick={() => setShowModal(false)}>
                      Cancel
                    </a>
                    <a className={`btn btn-danger ${isLoading? 'disabled' : ''}`} onClick={() => deleteLocation()}>
                      Delete
                    </a>
                  </div>
              )}
            </form>
          </div>
        </div>
      </Modal>

    </>
  );

  async function openModalAdd() {
    setIsLoading(false);
    resetLocationData()
    setShowModal(true);
    setShowEdit(false);
  };

  async function openModalEdit(element) {
    setIsLoading(false);
    setShowModal(true);
    setShowEdit(true);
    setFormData({
      address: element.address,
      phoneNumber: element.phoneNumber,
      city: element.city,
      postalCode: element.postalCode,
      locationId: element.id,
      longitude: element?.coordinate?.longitude || null,
      latitude: element?.coordinate?.latitude || null,
    });
  };

  async function getLocations(pageIndex, companyId) {
    try {
      const result = await getLocationsByCompanyId(pageIndex, 10, companyId, debouncedSearchQuery);
      setLocations(result.items);
      setTotalPages(result.totalPages);
      setCurrentPage(result.page);
      setTargetPage(result.page + 1);
    } catch (err) {
      console.error("Failed to fetch locations:", err);
      handleError(err);
    }
  };

  async function addLocation() {
    setIsLoading(true);
    try {
      formData['companyId'] = parseInt(companyId);
      formData['latitude'] = parseFloat(formData.latitude) || 0;
      formData['longitude'] = parseFloat(formData.longitude) || 0;
      const result = await createLocationForCompany(formData);
      toast.success("Location Created successfully");
      setLocations(prev => [...prev, result]);
      await getLocations(currentPage, companyId);
      setShowModal(false);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      handleError(err);
    }
  };

  async function updateLocation() {
    setIsLoading(true);

    try {
      const dataToSent = {
        city: formData?.city,
        postalCode: formData?.postalCode,
        phoneNumber: formData?.phoneNumber,
        address: formData?.address,
        latitude: formData?.latitude,
        longitude: formData?.longitude,
      };
      const result = await updateLocationCall(dataToSent, formData.locationId);
      setLocations((prevData) => {
        return prevData.map((item) => {
          if (item?.id === result?.id) {
            return {
              ...item,
              phoneNumber: result?.phoneNumber,
              address: result?.address,
              city: result?.city,
              coordinate: {
                longitude: result?.coordinate?.longitude || 0,
                latitude: result?.coordinate?.latitude || 0,
              },
            };
          }
          return item;
        });
      });
      toast.success("Location Updated successfully");
      setShowModal(false);
      setIsLoading(false);
      await getLocations(currentPage, companyId);
    } catch (err) {
      setIsLoading(false);
      handleError(err);
    }
  };


  async function handleInputChange (e) {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  async function deleteLocation () {
    try {
      await deleteCompanyLocation(formData.locationId);
      await getLocations(currentPage, companyId);
      toast.success("Location Deleted successfully");
      setShowModal(false);
      setShowEdit(false);
    } catch (err) {
      setShowModal(false);
      setShowEdit(false);
      handleError(err);
    }
  };


  function resetLocationData() {
    setFormData({
          address: "",
          phoneNumber: "",
          city: "",
          locationId: "",
          postalCode: "",
          latitude: null,
          longitude: null,
        }
    )
  }

};
