import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom/cjs/react-router-dom";
import Locations from "./Locations";
import Discounts from "./Discounts";
import CompanyInfo from "./CompanyInfo";
import Groups from "./Groups";
import {
    getGroupsByCompanyId, getCompanyByIdCall
} from "../../../../Api/ApiCalls";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {handleError} from "../../../../utils/SharedFunctions";

const CompanyDetails = () => {
  const { companyId } = useParams();
  const [companyGroups, setCompanyGroups] = useState([]);
  const [companyName, setCompanyName] = useState('')
  const [isSendStatistics, setIsSendStatistics] = useState(false)

  useEffect(() => {
    getGroups();
}, []);


  return (
    <>

        <Tabs className="mainTabs" forceRenderTabPanel={true}>
            <h3>{companyName}</h3>
            <TabList>
                <Tab>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" >
                        <path fill="white" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/>
                    </svg>
                    <span>
                        Info
                    </span>
                </Tab>
                <Tab>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                        <path d="M264.5 5.2c14.9-6.9 32.1-6.9 47 0l218.6 101c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 149.8C37.4 145.8 32 137.3 32 128s5.4-17.9 13.9-21.8L264.5 5.2zM476.9 209.6l53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 277.8C37.4 273.8 32 265.3 32 256s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0l152-70.2zm-152 198.2l152-70.2 53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 405.8C37.4 401.8 32 393.3 32 384s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0z"/>
                    </svg>
                    <span>
                        Groups
                    </span>
                </Tab>
                <Tab>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" >
                        <path d="M374.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-320 320c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l320-320zM128 128A64 64 0 1 0 0 128a64 64 0 1 0 128 0zM384 384a64 64 0 1 0 -128 0 64 64 0 1 0 128 0z"/>
                    </svg>
                    <span>
                        Discounts
                    </span>
                </Tab>
                <Tab>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" >
                      <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/>
                    </svg>
                    <span>
                      Locations
                    </span>
                </Tab>
            </TabList>

            <TabPanel>
                {/*Company Info*/}
                <CompanyInfo
                  companyId={companyId}
                  setIsSendStatistics={setIsSendStatistics}
                  getCompanyDetails={getCompanyById}
                />
            </TabPanel>
            <TabPanel>
                {/*Company Info*/}
                <Groups
                  companyId={companyId}
                  getDiscountGroups={getGroups}
                  initialGroups={companyGroups}
                />
            </TabPanel>
            <TabPanel>
                <Tabs className="subTabs">
                    <TabList>
                        {companyGroups.items?.map((group) => (
                            <Tab>
                                <span>
                                    { group.name }
                                </span>
                            </Tab>
                        ))}
                    </TabList>

                    {companyGroups.items?.map((group) => (
                        <TabPanel>
                            <Discounts
                                companyId={companyId}
                                groups={companyGroups.items}
                                groupId={group.id}
                                groupName={group.name}
                                isSendStatistics={isSendStatistics}
                            />
                        </TabPanel>
                    ))}
                </Tabs>
                {/*Actions Table*/}
            </TabPanel>
            <TabPanel>
              {/*Locations Table*/}
                  <Locations
                    companyId={companyId}
                  />
            </TabPanel>
        </Tabs>


    </>
  );


  async function getGroups()
  {
      try {
          const result = await getGroupsByCompanyId(1, 50, companyId, '');
          setCompanyGroups(result)
          return result
      } catch (err) {
          console.error(err);
          handleError(err);
      }
  };

  async function getCompanyById() {
    try {
      const result = await getCompanyByIdCall(companyId);
      setCompanyName(result.name)
      return result
    } catch (err) {
      console.error(err);
      handleError(err);
    }
  }


};

export default CompanyDetails;
