import React from 'react';
import { toast } from 'react-toastify';
import {PaymentRequestStatus} from "../../../../enums/PaymentRequestStatus";
import {updatePaymentStatusCall} from "../../../../Api/ApiCalls";

const PaymentsStatusDropDown = ({userId, paymentId, currentStatus, onStatusChange }) => {
  const handleChange = async (event) => {
    const newStatus = event.target.value;
    try {
      await updatePaymentStatus(userId, paymentId, newStatus);
      toast.success(`Status updated to ${newStatus}`);
      onStatusChange(newStatus);
    } catch (error) {
      console.error('Failed to update status:', error);
      toast.error('Failed to update the status. Please try again.');
    }
  };

  return (
      <select
          style={{
            padding: '8px 10px',
            borderRadius: '10px',
            cursor: 'pointer',
            outline: 'none',
            fontSize: '13px',
            height: 'unset',
            transition: "all 1s ease-in-out",
            width: '75%',
            paddingRight:"10px",
            margin: '0 auto',
            display: 'block',
          }}
          value={currentStatus}
          onChange={handleChange}
        >
        {
          Object.entries(PaymentRequestStatus).map(status => {
            return  <option value={status[0]}> {status[1]} </option>
          })
        }
      </select>
  );
};

const updatePaymentStatus = async (userId,paymentId, newStatus) => {
    const response = await updatePaymentStatusCall(userId,paymentId, newStatus)
    return response.data;
};

export default PaymentsStatusDropDown;
