import React, {useState} from "react";
import Button from "./Button";
import {toast} from "react-toastify";
import PaymentsStatusDropDown from "./PaymentsStatusDropDown";

const Payments = ({ userId, payments, confirmEndKwm }) => {
  const [localPayments, setLocalPayments ] = useState(payments);
  const handleStatusChange = (newStatus, paymentId) => {
    setLocalPayments((prevPayments) =>
        prevPayments.map((payment) =>
            payment.paymentId === paymentId
                ? { ...payment, status: newStatus }
                : payment
        )
    );
  };
  return (
    <>
    <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="h3 font-weight-bold text-primary">
          Generated Payment Reminders
        </div>
    </div>
    <table className="static-table">
      <thead>
      <tr>
        <th>Payment request</th>
        <th>Type</th>
        <th>Price</th>
        <th>Payment Status</th>
        <th>KWM</th>
        <th>Payment Reminder</th>
        <th>Payment Link</th>
      </tr>
      </thead>
      <tbody>
      {
          localPayments &&
          localPayments.map((payment, index) => {
            return (
                <tr>
                  <td>Payment Sub {localPayments.length - index}</td>
                  <td>{payment?.type}</td>
                  <td>{parseFloat(payment?.price) || '-'}</td>
                  <td>
                    <PaymentsStatusDropDown
                        paymentId={payment.paymentId}
                        userId={userId}
                        currentStatus={payment.status}
                        onStatusChange={(newStatus) => handleStatusChange(newStatus, payment.paymentId)}
                    />
                  </td>
                  <td>
                    {
                        payment.isReminderActive && (
                            <Button
                                text="End KWM"
                                variant="red"
                                disabled={payment.status === 'PAID'}
                                onClick={() => {
                                  confirmEndKwm(payment.paymentId)
                                }}
                                iconClassName="fas fa-solid fa-ban"
                            />
                        )
                    }
                  </td>
                  <td>{(payment?.isReminderActive && payment.status !== 'PAID' ? 'Active' : 'Not Active') + " (" + payment?.reminderLevel + " reminders)"}</td>
                  <td>
                    <Button
                        text="Go To Payment"
                        variant="blue"
                        disabled={payment.status === 'PAID'}
                        onClick={() => {
                          window.open(payment.paymentLink, '_blank').focus();
                        }}
                        iconClassName="fas fa-solid fa-globe"
                    />
                    <i className={'fas fa-solid fa-copy cursor-pointer'} style={{paddingLeft: "10px", color: '#0473ae'}}
                       onClick={() => {
                         navigator.clipboard.writeText(payment.paymentLink).then(
                             () => {
                               toast.success('Payment link copied to clipboard')
                             },
                             (err) => {
                               console.error('Failed to copy the payment link: ', err);
                             }
                         );
                       }}
                    ></i>
                  </td>

                </tr>
            );
          })
      }
      </tbody>
    </table>
    </>
  );
};

export default Payments;
